export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/edit',
    name: 'apps-users-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/user/users-edit/UserEditTabAccount.vue'),
  },
  // *===============================================---*
  // *--------- RECEIPT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/receipts/list',
    name: 'apps-receipts-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/receipts/List.vue'),
  },
  {
    path: '/apps/receipts/add',
    name: 'apps-receipts-add',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/receipts/Add.vue'),
  },
  {
    path: '/apps/receipts/details',
    name: 'apps-receipts-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/receipts/Details.vue'),
  },
  // *===============================================---*
  // *--------- CONTRACT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/contracts/list',
    name: 'apps-contracts-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/contracts/List.vue'),
  },
  {
    path: '/apps/contracts/add',
    name: 'apps-contracts-add',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/contracts/Add.vue'),
  },
  {
    path: '/apps/contracts/details',
    name: 'apps-contracts-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/contracts/Details.vue'),
  },
  // *===============================================---*
  // *--------- CERTIFICATE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/certificates/list',
    name: 'apps-certificates-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/certificates/List.vue'),
  },
    // *===============================================---*
  // *--------- CERTIFICATE VALIDITY ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/certificate-validator',
    name: 'certificate-validator',
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
    component: () => import('@/views/apps/certificates/Validator.vue'),
  },
  // *===============================================---*
  // *--------- INVOICE DETAIL ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/invoice-details',
    name: 'apps-invoice-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/invoices/corporate/corporate-list/Details.vue'),
  },

  // *===============================================---*
  // *--------- BRANCH ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/branch/list',
    name: 'apps-branches-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/branch/branches-list/BranchesList.vue'),
  },
  {
    path: '/apps/branch/edit',
    name: 'apps-branches-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/branch/branches-edit/BranchesEdit.vue'),
  },

  // *===============================================---*
  // *--------- INVOICES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/invoices/internal',
    name: 'apps-invoices-internal',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/invoices/internal/internal-list/InvoicesList.vue'),
  },
  {
    path: '/apps/invoices/local',
    name: 'apps-invoices-local',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/invoices/local/local-list/InvoicesList.vue'),
  },
  {
    path: '/apps/invoices/corporate',
    name: 'apps-invoices-corporate',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/invoices/corporate/corporate-list/InvoicesList.vue'),
  },
  {
    path: '/apps/invoices/void',
    name: 'apps-invoices-void',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/invoices/void/void-list/InvoicesList.vue'),
  },
  {
    path: '/apps/void',
    name: 'apps-void',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/void/void.vue'),
  },
  {
    path: '/apps/change-password',
    name: 'apps-change-password',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/change_password/change_password.vue'),
  },
  {
    path: '/apps/settings',
    name: 'apps-settings',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/settings/settings.vue'),
  },
  // *===============================================---*
  // *--------- PROCUREMENTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/procurements/purchase_order_list',
    name: 'apps-procurement-purchase-order-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/PurchaseOrderList.vue'),
  },
  {
    path: '/apps/procurements/purchase_order_add',
    name: 'apps-procurement-purchase-order-add',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/PurchaseOrderAddNew.vue'),
  },
  // *===============================================---*
  // *--------- ROLES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/roles/list',
    name: 'apps-roles-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/roles/roles-list/RolesList.vue'),
  },

  // Executive
  {
    path: '/apps/income/external_all',
    name: 'apps-income-external-all',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalAll.vue'),
  },
  {
    path: '/apps/income/external_all/:startDate/:endDate',
    name: 'apps-income-external-all-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalAll.vue'),
  },
  {
    path: '/apps/income/corporate',
    name: 'apps-income-corporate',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Corporate.vue'),
  },
  {
    path: '/apps/income/corporate/:startDate/:endDate',
    name: 'apps-income-corporate-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Corporate.vue'),
  },
  {
    path: '/apps/income/local',
    name: 'apps-income-local',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Local.vue'),
  },
  {
    path: '/apps/income/local/:startDate/:endDate',
    name: 'apps-income-local-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Local.vue'),
  },
  {
    path: '/apps/income/internal',
    name: 'apps-income-internal',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Internal.vue'),
  },
  {
    path: '/apps/income/internal/:startDate/:endDate',
    name: 'apps-income-internal-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Internal.vue'),
  },
  {
    path: '/apps/income/external_nett',
    name: 'apps-income-external-nett',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalNett.vue'),
  },
  {
    path: '/apps/income/external_nett/:startDate/:endDate',
    name: 'apps-income-external-nett-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalNett.vue'),
  },
]
